import React from 'react';
import {
  DontDo,
  DontDoItem,
  PageHero,
  Link,
  List,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../../data/pages/words.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="Transactional Emails" subnav="words">
      <PageHero heroData={pageHeroData} tierTwo="Writing Guidelines" tierThree="Transactional Emails" />

      <Section>
        <Paragraph>
          We shouldn’t rely on email to deliver time-sensitive information. It’s considered a less intrusive means of
          getting on their radar, so don't expect them to act or respond right away. For shorter, more timely alerts,
          try a <Link href="/words/writing-guidelines/mobile-notifications">mobile notification</Link> instead.
        </Paragraph>
        <Paragraph>
          Because an email’s shelf life is longer than that of a mobile notification, make sure to bundle messages and
          spread them out as much as possible.
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Subject Lines</SectionSubhead>
        <Paragraph>
          We’ll keep these tips short and sweet—just like the subject <em>should be</em>.
        </Paragraph>
        <List type="unordered">
          <li>
            <strong>Keep your subject line under 50 characters.</strong> If you can do 30, even better. Anything longer
            is cut off by the email client.
          </li>
          <li>
            <strong>Personalization is important.</strong> First names aren’t as effective as a specific product or team
            they’re familiar with.
          </li>
          <li>
            <strong>Don’t repeat ‘Hudl’ in the subject.</strong> Our name already shows up as the sender.
          </li>
          <li>
            <strong>Be careful with punctuation.</strong> Exclamations are a no-go. Feel free to ask a question, but
            always include the answer in the body.
          </li>
          <li>
            <strong>Use sentence case.</strong> It’s easier on the eyes. That said, don’t put periods at the end. If you
            need one to separate two sentences (shouldn’t happen often), that’s fine.
          </li>
        </List>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="come off as bossy or urgent (a risk with exclamations)."
            img="transactionalemail-punctuation-dont"
          />
          <DontDoItem
            type="do"
            text="keep the subject line short, with quality personalization."
            img="transactionalemail-punctuation-do"
          />
        </DontDo>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="refer to a person or their team in third person."
            img="transactionalemail-repeat-dont"
          />
          <DontDoItem type="do" text="speak directly to the recipient." img="transactionalemail-repeat-do" />
        </DontDo>
      </Section>
      <Section>
        <SectionSubhead>Body Copy</SectionSubhead>
        <Paragraph>
          Every transactional email serves a very specific purpose. For the sake of brevity, fulfill that purpose and be
          done.
        </Paragraph>
        <List type="ordered">
          <li>
            Make the email <em>scannable</em>.{' '}
            <Link href="/words/writing-guidelines/headings-lists">Headings and lists</Link> are incredibly helpful on
            this front.
          </li>
          <li>
            The whole thing should read as one cohesive thought with a <strong>beginning, middle and end</strong>. Give
            them a sentence or two explaining why they’re receiving the email, then state the important info as clearly
            as possible. Close it with contact information or a quick CTA, and you’re done!
          </li>
          <li>
            We should always speak directly to the recipient—don’t be afraid of{' '}
            <Link href="/words/writing-guidelines/your-my-neutral">“you” and “your”</Link>. Everything automated should
            come from “we” or “us” as a company.
          </li>
          <li>
            <strong>You get one exclamation per email, max.</strong> That does not mean you have to include one. Save it
            for genuine excitement, not to get the reader excited about something objectively average.
          </li>
          <li>
            If you link anything, make sure to follow our microcopy rules under the{' '}
            <Link href="/components/link/design#Microcopy">link component</Link>.
          </li>
        </List>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="cram everything together as one thought with no clear focus."
            img="transactionalemail-bodycopy-dont"
          />
          <DontDoItem
            type="do"
            text="break things up with headings and lists for easier scanning."
            img="transactionalemail-bodycopy-do"
          />
        </DontDo>
      </Section>
      <Section>
        <SectionSubhead>CTAs</SectionSubhead>
        <Paragraph>
          Be sure to check our guidelines on{' '}
          <Link href="/words/content-elements/calls-to-action">title-to-CTA congruence</Link>. Consider the subject line
          your “title” and make the email flow from start to finish.
        </Paragraph>
        <Paragraph>
          For the sake of providing a clear next step, <strong>shoot for a single CTA in each email</strong>. To make it
          pop, use the <Link href="/components/buttons/button/design">button component</Link>
          —and abide by those microcopy rules!
        </Paragraph>

        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="detach the CTA from what’s implied by the subject."
            img="transactionalemail-cta-dont"
          />
          <DontDoItem
            type="do"
            text="build a CTA from the content of your subject line."
            img="transactionalemail-cta-do"
          />
        </DontDo>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
